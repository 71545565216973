<template>
  <div class="loginBg">
    <!-- 登录 -->
    <div class="loginBox" v-if="loginWay">
      <img class="portrait" :src="portrait" alt="" />
      <img class="lefthand" :src="lefthand" alt="" />
      <img class="righthand" :src="righthand" alt="" />
      <el-tabs
        class="tabBox padding-20"
        v-model="loginType"
        @tab-click="switchLoginState"
        v-show="loginBoxShow"
      >
        <el-tab-pane class="tabTitle" label="短信登录" name="first">
          <el-row>
            <el-col class="" :span="10">
              <div class="loginTitle">
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon01" alt="" />
                  </div>
                  <div class="text">
                    <span>品牌实力</span><br />
                    <span>100万+建筑人才首选</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon02" alt="" />
                  </div>
                  <div class="text">
                    <span>在线微聊</span><br />
                    <span>在线开聊更安全便捷</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon03" alt="" />
                  </div>
                  <div class="text">
                    <span>免费服务</span><br />
                    <span>价格咨询、推荐单位</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane class="tabTitle" label="密码登录" name="second">
          <el-row>
            <el-col class="" :span="10">
              <div class="loginTitle">
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon01" alt="" />
                  </div>
                  <div class="text">
                    <span>品牌实力</span><br />
                    <span>100万+建筑人才首选</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon02" alt="" />
                  </div>
                  <div class="text">
                    <span>在线微聊</span><br />
                    <span>在线开聊更安全便捷</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon03" alt="" />
                  </div>
                  <div class="text">
                    <span>免费服务</span><br />
                    <span>价格咨询、推荐单位</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <!-- 登录form -->
        <el-col class="positionBox padding-20" :span="14">
          <div class="formBox">
            <el-form :model="loginInfo">
              <el-form-item>
                <el-input placeholder="请输入手机号" v-model="loginInfo.phone">
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-mobile-phone"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item v-if="loginType != 'first'">
                <el-input
                  placeholder="请输入密码"
                  type="password"
                  v-model="loginInfo.password"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <DragBar></DragBar>
              </el-form-item>
              <!-- 登录验证码 -->
              <el-form-item v-if="loginType == 'first'">
                <el-input placeholder="请输入验证码" v-model="loginInfo.code">
                  <el-button
                    slot="append"
                    type="primary"
                    :disabled="codeBtn"
                    v-prevent-repeat-click
                    v-if="smsTime > 59"
                    @click="gainLoginCode"
                  >
                    获取验证码
                  </el-button>
                  <template slot="append" v-if="smsTime < 60">
                    {{ smsTime }}秒后可重发
                  </template>
                </el-input>
              </el-form-item>
              <el-button
                type="primary"
                class="loginBtn"
                :disabled="codeBtn"
                v-prevent-repeat-click
                @click="goLogin"
              >
                立即登录
              </el-button>
            </el-form>
            <div class="loginConfig">
              <el-checkbox v-model="loginState">记录登录状态</el-checkbox>
              <el-link type="danger" @click="switchFind">忘记密码</el-link>
              <el-link type="primary" @click="switchRegist">注册新用户</el-link>
            </div>
            <div class="otherLine">
              <span>其他方式登录</span>
            </div>
            <div class="text-center margin-top-20">
              <img
                :src="wechatIcon"
                alt=""
                style="width:36px;"
                @click="changWXLogin"
              />
            </div>
          </div>
        </el-col>
      </el-tabs>
      <!-- 微信登录 -->
      <div class="wxLoginBox" v-show="wxloginBoxShow">
        <div class="text-left backLoginBox">
          <span @click="backLoginBox">&lt;&lt; 账号登录</span>
          <!-- <el-button type="text" @click="backLoginBox">&lt; 账号登录</el-button> -->
        </div>
        <div id="codeBox"></div>
      </div>
    </div>
    <!-- 注册 -->
    <div class="registBox" v-if="registWay">
      <img class="portrait" :src="portrait" alt="" />
      <img class="lefthand" :src="lefthand" alt="" />
      <img class="righthand" :src="righthand" alt="" />
      <el-tabs
        class="tabBox2 padding-20"
        v-model="registType"
        @tab-click="switchRegistState"
      >
        <el-tab-pane class="tabTitle" label="我是求职者" name="first">
          <el-row>
            <el-col class="" :span="10">
              <div class="loginTitle">
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon01" alt="" />
                  </div>
                  <div class="text">
                    <span>品牌实力</span><br />
                    <span>100万+建筑人才首选</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon02" alt="" />
                  </div>
                  <div class="text">
                    <span>在线微聊</span><br />
                    <span>在线开聊更安全便捷</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon03" alt="" />
                  </div>
                  <div class="text">
                    <span>免费服务</span><br />
                    <span>价格咨询、推荐单位</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane class="tabTitle" label="我是企业" name="second">
          <el-row>
            <el-col class="" :span="10">
              <div class="loginTitle">
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon04" alt="" />
                  </div>
                  <div class="text">
                    <span>领跑全网</span><br />
                    <span>行业领先的兼职平台</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon05" alt="" />
                  </div>
                  <div class="text">
                    <span>委托寻证</span><br />
                    <span>专业省心信赖首选</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon06" alt="" />
                  </div>
                  <div class="text">
                    <span>品质服务</span><br />
                    <span>专业顾问高效贴心</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane class="tabTitle" label="我是猎头" name="three">
          <el-row>
            <el-col class="" :span="10">
              <div class="loginTitle">
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon07" alt="" />
                  </div>
                  <div class="text">
                    <span>猎头首选</span><br />
                    <span>行业领先的兼职平台</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon08" alt="" />
                  </div>
                  <div class="text">
                    <span>海量人才</span><br />
                    <span>简历更新量领跑全网</span>
                  </div>
                </div>
                <div class="imgText clearfix">
                  <div>
                    <img :src="icon09" alt="" />
                  </div>
                  <div class="text">
                    <span>高效招聘</span><br />
                    <span>全方位提升招聘效果</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <!-- 注册form -->
        <el-col class="positionBox padding-20" :span="14">
          <div class="formBox">
            <el-form :model="registInfo">
              <el-form-item>
                <el-input
                  placeholder="请输入手机号"
                  v-model="registInfo.u_phone"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-mobile-phone"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <DragBar></DragBar>
              </el-form-item>
              <el-form-item>
                <el-input placeholder="请输入验证码" v-model="registInfo.code">
                  <el-button
                    slot="append"
                    type="primary"
                    :disabled="codeBtn"
                    v-if="smsTime > 59"
                    v-prevent-repeat-click
                    @click="gainRegistCode"
                  >
                    获取验证码
                  </el-button>
                  <template slot="append" v-if="smsTime < 60">
                    {{ smsTime }}秒后可重发
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  placeholder="请设置密码"
                  type="password"
                  v-model="registInfo.u_password"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  placeholder="请再次确认密码"
                  type="password"
                  v-model="registInfo.u_passwords"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-checkbox v-model="registWill">
                已阅读并同意
              </el-checkbox>
              <el-link type="primary" @click="goNotice">
                《建搜搜服务协议及隐私政策》
              </el-link>
              <el-button
                type="primary"
                class="loginBtn"
                v-prevent-repeat-click
                @click="goRegist"
              >
                立即注册
              </el-button>
            </el-form>
            <div class="text-center">
              已有账号，
              <el-link @click="switchLogin" type="primary">
                直接登录
              </el-link>
            </div>
          </div>
        </el-col>
      </el-tabs>
    </div>
    <!-- 找回密码 -->
    <div class="findBox" v-if="findWay">
      <img class="portrait" :src="portrait" alt="" />
      <img class="lefthand" :src="lefthand" alt="" />
      <img class="righthand" :src="righthand" alt="" />
      <el-col class="findform">
        <div class="formBox">
          <el-form :model="findInfo">
            <el-form-item>
              <el-input placeholder="请输入手机号" v-model="findInfo.u_phone">
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-mobile-phone"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <DragBar></DragBar>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="请输入验证码" v-model="findInfo.code">
                <el-button
                  slot="append"
                  type="primary"
                  :disabled="codeBtn"
                  v-prevent-repeat-click
                  v-if="smsTime > 59"
                  @click="gainFindCode"
                >
                  获取验证码
                </el-button>
                <template slot="append" v-if="smsTime < 60">
                  {{ smsTime }}秒后可重发
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="请输入密码"
                type="password"
                v-model="findInfo.u_password"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="请确认密码"
                type="password"
                v-model="findInfo.u_passwords"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-button
              type="primary"
              class="loginBtn"
              v-prevent-repeat-click
              @click="goFind"
            >
              重置密码
            </el-button>
            <div class="text-center">
              <el-link type="primary" @click="findToLogin">登录 </el-link>
              <el-link style="color: #2573f1;padding: 0px 5px"> / </el-link>
              <el-link type="primary" @click="findToRegist"> 注册</el-link>
            </div>
          </el-form>
        </div>
      </el-col>
    </div>
  </div>
</template>
<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import DragBar from "@/components/dragBar/dragbar.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "login",
  components: {
    DragBar,
  },
  data() {
    return {
      loginType: "first",
      registType: "first",
      portrait: require("@/assets/img/loginbg01.png"),
      lefthand: require("@/assets/img/loginlefthand.png"),
      righthand: require("@/assets/img/loginrighthand.png"),
      wechatIcon: require("@/assets/icon/loginWechat.png"),
      icon01: require("@/assets/icon/login01.png"),
      icon02: require("@/assets/icon/login02.png"),
      icon03: require("@/assets/icon/login03.png"),
      icon04: require("@/assets/icon/login04.png"),
      icon05: require("@/assets/icon/login05.png"),
      icon06: require("@/assets/icon/login06.png"),
      icon07: require("@/assets/icon/login07.png"),
      icon08: require("@/assets/icon/login08.png"),
      icon09: require("@/assets/icon/login09.png"),
      smsTime: "60",
      loginState: false,
      loginWay: true,
      registWay: false,
      registWill: false,
      findWay: false,
      loginInfo: {
        type: 1,
      },
      registInfo: {
        u_identity: 1,
      },
      findInfo: {},
      loginBoxShow: true,
      wxloginBoxShow: false,
    };
  },
  created() {
    let stateCode = sessionStorage.getItem("loginOptions");
    if (stateCode == 1) {
      this.registWay = false;
      this.loginWay = true;
    } else if (stateCode == 2 || stateCode == 3) {
      this.loginWay = false;
      this.registWay = true;
    }
    // 根据登陆者类型判断绑定微信类型
    // if (sessionStorage.getItem("loginer") != undefined) {
    //   let type = sessionStorage.getItem("loginer");
    //   if (type == 1) {
    //     this.registType = "first";
    //   } else if (type == 2) {
    //     this.registType = "second";
    //   } else if (type == 3) {
    //     this.registType = "three";
    //   }
    // }
    if (sessionStorage.getItem("loginOptions") == 3) {
      return;
    }
    // 如果store有值，刷新当前页面
    if (sessionStorage.getItem("store")) {
      window.location.replace(window.location.href);
    }
  },
  computed: {
    ...mapState(["loginOptions", "dragState", "userInfo", "openid", "unionid"]),
    codeBtn() {
      return !this.dragState;
    },
  },
  methods: {
    ...mapMutations(["setUserInfo", "changeLogin", "changeDragState"]),
    btn() {
      console.log(sessionStorage.getItem("loginOptions"));
      sessionStorage.setItem("loginOptions", 1);
      console.log(sessionStorage.getItem("loginOptions"));
    },
    switchRegist() {
      this.loginWay = false;
      this.registWay = true;
      this.changeDragState({
        dragState: false,
      });
    },
    switchLogin() {
      this.registWay = false;
      this.loginWay = true;
      this.changeDragState({
        dragState: false,
      });
      sessionStorage.setItem("loginOptions", 1);
    },
    switchFind() {
      this.loginWay = false;
      this.findWay = true;
    },
    findToLogin() {
      this.findWay = false;
      this.loginWay = true;
    },
    findToRegist() {
      this.findWay = false;
      this.registWay = true;
    },
    switchRegistState(tab) {
      if (tab.index == 0) {
        // 求职者
        this.registInfo.u_identity = 1;
      } else if (tab.index == 1) {
        // 企业
        this.registInfo.u_identity = 2;
      } else if (tab.index == 2) {
        // 猎头
        this.registInfo.u_identity = 3;
      }
    },
    // 注册获取验证码
    gainRegistCode() {
      let tel = "+86" + this.registInfo.u_phone;
      this.$http.post("index/login/code", { phone: tel }).then((res) => {
        if (res.data.resultCode === 1) {
          // this.$message.success("成功！");
          // 验证码发送定时器
          let _this = this;
          let timer = setInterval(function() {
            _this.smsTime--;
            if (_this.smsTime === 0) {
              clearInterval(timer);
              _this.smsTime = 60;
            }
          }, 1000);
        }
      });
    },
    goRegist() {
      if (!this.registWill) {
        this.$message.warning("请先同意用户协议！");
        return;
      }
      if (sessionStorage.getItem("loginOptions") == 3) {
        // e二维码首次登陆时，要注册
        this.registInfo.openid = this.openid;
        this.registInfo.unionid = this.unionid;
        this.registInfo.login_type = "pc";
        this.$http
          .post("index/login/relationlogin", this.registInfo)
          .then((res) => {
            if (res.data.resultCode === 1) {
              sessionStorage.setItem("loginOptions", 1);
              this.$message.success("成功！");
              this.setUserInfo({
                userInfo: res.data.resultData[0],
              });
              this.changeLogin({ Authorization: res.data.resultData[0].token });
              if (res.data.resultData[0].u_identity == 1) {
                // 判断用户类型
                this.$http.post("talent/index/baseinfo").then((res) => {
                  if (res.data.resultCode === 1) {
                    if (res.data.resultData.resume == "") {
                      this.$router.push({ path: "/talentpage/firstResume" });
                      return;
                    }
                    this.$notify.success({
                      message: "登录成功！",
                    });
                    this.$router.push({ path: "/Home/index" });
                  }
                });
              } else if (
                res.data.resultData[0].u_identity == 2 ||
                res.data.resultData[0].u_identity == 3
              ) {
                if (res.data.resultData[0].u_company === 0) {
                  this.$router.push({ path: "/comPage/comrealSel" });
                  return;
                }
                this.$router.push({ path: "/Home/index" });
                this.$notify.success({
                  message: "登录成功！",
                });
              } else {
                this.$router.push({ path: "/Home/index" });
                this.$notify.success({
                  message: "登录成功！",
                });
              }
            }
          });
      } else {
        // 普通注册
        this.$http.post("index/login/register", this.registInfo).then((res) => {
          if (res.data.resultCode === 1) {
            sessionStorage.setItem("loginOptions", 1);
            this.$message.success("注册成功，正在登录中！");
            let info = {
              type: 2,
              phone: this.registInfo.u_phone,
              password: this.registInfo.u_password,
              login_type: "pc",
            };
            this.$http.post("index/login/login", info).then((res) => {
              if (res.data.resultCode === 1) {
                // 登陆成功
                this.setUserInfo({
                  userInfo: res.data.resultData[0],
                });
                this.changeLogin({
                  Authorization: res.data.resultData[0].token,
                });
                if (res.data.resultData[0].u_identity == 1) {
                  // 判断用户类型
                  this.$http.post("talent/index/baseinfo").then((res) => {
                    if (res.data.resultCode === 1) {
                      if (res.data.resultData.resume == "") {
                        this.$router.push({ path: "/talentpage/firstResume" });
                        return;
                      }
                      this.$notify.success({
                        message: "登录成功！",
                      });
                      this.$router.push({ path: "/Home/index" });
                    }
                  });
                } else if (
                  res.data.resultData[0].u_identity == 2 ||
                  res.data.resultData[0].u_identity == 3
                ) {
                  if (res.data.resultData[0].u_company === 0) {
                    this.$router.push({ path: "/comPage/comrealSel" });
                    return;
                  }
                  this.$router.push({ path: "/Home/index" });
                  this.$notify.success({
                    message: "登录成功！",
                  });
                } else {
                  this.$router.push({ path: "/Home/index" });

                  this.$notify.success({
                    message: "登录成功！",
                  });
                }
                sessionStorage.setItem(
                  "store",
                  JSON.stringify(this.$store.state)
                );
              }
            });
          }
        });
      }
    },
    switchLoginState(tab) {
      if (tab.index == 0) {
        // 短信登录
        this.loginInfo.type = 1;
        this.changeDragState({
          dragState: false,
        });
      } else if (tab.index == 1) {
        // 密码登录
        this.loginInfo.type = 2;
        // this.codeBtn = true;
        this.changeDragState({
          dragState: false,
        });
      }
    },
    // 登录验证码接口
    gainLoginCode() {
      let tel = "+86" + this.loginInfo.phone;
      this.$http.post("index/login/codes", { phone: tel }).then((res) => {
        if (res.data.resultCode === 1) {
          // 验证码发送定时器
          let _this = this;
          let timer = setInterval(function() {
            _this.smsTime--;
            if (_this.smsTime === 0) {
              clearInterval(timer);
              _this.smsTime = 60;
            }
          }, 1000);
          this.$message.success("验证码已发送，请您注意查收！");
        }
      });
    },
    // 登录接口
    goLogin() {
      if (!this.loginInfo.phone) {
        this.$message.warning("登录手机号不能为空！");
        return;
      }
      if (this.loginInfo.type == 1 && !this.loginInfo.code) {
        this.$message.warning("验证码不能为空！");
        return;
      }
      if (this.loginInfo.type == 2 && !this.loginInfo.password) {
        this.$message.warning("登录密码不能为空！");
        return;
      }
      this.loginInfo.login_type = "pc";
      this.$http.post("index/login/login", this.loginInfo).then((res) => {
        if (res.data.resultCode === 1) {
          sessionStorage.setItem("loginOptions", 1);
          // 登陆成功
          this.setUserInfo({
            userInfo: res.data.resultData[0],
          });
          this.changeLogin({ Authorization: res.data.resultData[0].token });
          if (res.data.resultData[0].u_identity == 1) {
            // 判断用户类型
            this.$http.post("talent/index/baseinfo").then((res) => {
              if (res.data.resultCode === 1) {
                if (res.data.resultData.resume == "") {
                  this.$router.push({ path: "/talentpage/firstResume" });
                  return;
                }
                this.$notify.success({
                  message: "登录成功！",
                });
                this.$router.push({ path: "/Home/index" });
              }
            });
          } else if (
            res.data.resultData[0].u_identity == 2 ||
            res.data.resultData[0].u_identity == 3
          ) {
            if (res.data.resultData[0].u_company === 0) {
              this.$router.push({ path: "/comPage/comrealSel" });
              return;
            }
            this.$router.push({ path: "/Home/index" });
            this.$notify.success({
              message: "登录成功！",
            });
          } else {
            this.$router.push({ path: "/Home/index" });
            this.$notify.success({
              message: "登录成功！",
            });
          }
          sessionStorage.setItem("store", JSON.stringify(this.$store.state));
        }
      });
    },
    gainFindCode() {
      let tel = "+86" + this.findInfo.u_phone;
      this.$http.post("index/login/codess", { phone: tel }).then((res) => {
        if (res.data.resultCode === 1) {
          // 验证码发送定时器
          let _this = this;
          let timer = setInterval(function() {
            _this.smsTime--;
            if (_this.smsTime === 0) {
              clearInterval(timer);
              _this.smsTime = 60;
            }
          }, 1000);
          // this.$message.success("验证码已发送，请您注意查收！");
        }
      });
    },
    goFind() {
      this.$http.post("index/login/retrieve", this.findInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.$message.success(res.data.resultMsg);
          this.loginWay = true;
          this.findWay = false;
          this.loginType = "second";
          this.$set(this.loginInfo, "phone", this.findInfo.u_phone);
          this.$set(this.loginInfo, "password", this.findInfo.u_password);
          this.loginInfo.type = 2;
        }
      });
    },
    setWxerwma() {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      const wxElement = document.body.appendChild(s);
      wxElement.onload = function() {
        var obj = new WxLogin({
          id: "codeBox", // 需要显示的容器id
          appid: "wxa8ea41b6aba67301", // 公众号appid
          scope: "snsapi_login", // 网页默认即可
          redirect_uri: "http%3A%2F%2Fwww.51jiansousou.com", // 授权成功后回调的url  www.51jiansousou.com   zhaomy-frp.4kb.cn
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: "", // 提供"black"、"white"可选。二维码的样式
          href: "", // 外部css文件url，需要https
        });
      };
    },
    changWXLogin() {
      this.loginBoxShow = false;
      this.wxloginBoxShow = true;
      this.setWxerwma();
    },
    backLoginBox() {
      this.wxloginBoxShow = false;
      this.loginBoxShow = true;
    },
    goNotice() {
      let routeUrl = this.$router.resolve({
        name: "Notice",
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("userInfo");
    sessionStorage.removeItem("store");
    this.$store.replaceState(Object.assign({}, this.$store.state, {}));
  },
};
</script>
<style scoped>
.loginBg {
  min-height: 900px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1e7fe;
  position: relative;
  background-image: url(../../assets/img/loginbg.png);
}
.portrait,
.lefthand,
.righthand {
  position: absolute;
}
.portrait {
  right: 140px;
  top: -190px;
  width: 430px;
  z-index: 1;
}
.lefthand {
  right: 545px;
  z-index: 4;
  top: -23px;
  width: 50px;
}
.righthand {
  right: 395px;
  z-index: 4;
  top: -23px;
  width: 50px;
}
.positionBox {
  position: absolute;
  right: 0px;
  z-index: 333;
  top: 25px;
}
.loginBox,
.registBox,
.findBox {
  width: 800px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 14px 24px 9px rgba(185, 185, 185, 0.5);
  border-radius: 16px;
  position: relative;
  z-index: 2;
}
.registBox {
  height: 560px;
}
.findBox {
  width: 600px;
  height: 460px;
}
.findBox .portrait {
  right: 60px;
}
.findBox .lefthand {
  right: 460px;
}
.findBox .righthand {
  right: 310px;
}
.findBox .positionBox {
  padding-left: 70px;
  padding-right: 70px;
}
.findform {
  background-color: #fff;
  position: relative;
  z-index: 1;
  height: 460px;
  border-radius: 16px;
  padding: 20px 70px;
}
.tabBox,
.tabBox2 {
  position: relative;
  z-index: 3;
  border-radius: 16px;
  background-color: #fff;
}
.tabBox .loginTitle {
  padding-top: 50px;
}
.tabBox2 .loginTitle {
  padding-top: 80px;
}
.imgText {
  padding: 20px;
}
.imgText div {
  float: left;
}
.imgText > div:first-child {
  width: 55px;
}
.imgText .text {
  padding-left: 15px;
}
.padding-20 {
  padding: 20px;
}
.imgText .text span:first-child {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  display: inline-block;
  padding: 9px;
  padding-top: 0;
}
.imgText .text span:last-child {
  font-size: 17px;
  color: #999999;
  line-height: 24px;
  display: inline-block;
  padding-left: 9px;
}
.formBox {
  padding: 30px 30px 0 30px;
}
.loginBtn {
  width: 100%;
  margin: 15px 0 20px;
  border-radius: 32px;
}
.loginBtn:hover {
  box-shadow: 0px 8px 18px 0px #8bb7ff;
}
/* .loginBtn:hover {
  background-color: #0a56d0;
  border-color: #0a56d0;
}
.loginBg.is-disabled:hover {
  background-color: #92b9f8;
  border-color: #92b9f8;
} */
.loginConfig .el-link {
  float: right;
}
.loginConfig .el-link:last-child {
  margin-right: 63px;
}
.otherLine {
  text-align: center;
  padding: 3px;
  border-bottom: 1px solid #979797;
}
.otherLine span {
  color: #999999;
  background-color: #fff;
  padding: 0 9px;
  position: relative;
  top: 10px;
}
.wxLoginBox {
  background: #fff;
  z-index: 3;
  position: relative;
  border-radius: 20px;
  text-align: center;
  padding-top: 30px;
  height: 470px;
}
.backLoginBox {
  padding: 0px 20px 15px;
  border-bottom: 2px solid #2361ff;
}
.backLoginBox span:hover {
  color: #2573f1;
  cursor: pointer;
}
#codeBox {
  padding-top: 10px;
}
</style>
